define("ember-ref-bucket/helpers/ref-to", ["exports", "@ember/component/helper", "ember-ref-bucket/utils/ref", "@ember/destroyable", "@ember/application"], function (_exports, _helper, _ref3, _destroyable, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class RefToHelper extends _helper.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "_watcher", null);
    }

    compute(_ref, _ref2) {
      let [name] = _ref;
      let {
        bucket,
        tracked
      } = _ref2;
      const bucketRef = bucket || (0, _application.getOwner)(this);

      if (this._name !== name) {
        if (this._watcher) {
          (0, _destroyable.unregisterDestructor)(this, this._watcher);
        }

        this._watcher = (0, _ref3.watchFor)(name, bucketRef, () => {
          this.recompute();
        });
        (0, _destroyable.registerDestructor)(this, this._watcher);
        this._name = name;
      }

      if (tracked) {
        return (0, _ref3.bucketFor)(bucketRef).getTracked(name);
      } else {
        return (0, _ref3.bucketFor)(bucketRef).get(name);
      }
    }

  }

  _exports.default = RefToHelper;
});