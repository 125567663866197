define("ember-validators/utils/unwrap-proxy", ["exports", "@ember/array/proxy", "@ember/object/proxy"], function (_exports, _proxy, _proxy2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = unwrapProxy;
  _exports.isProxy = isProxy;

  function unwrapProxy(o) {
    return isProxy(o) ? unwrapProxy(o.content) : o;
  }

  function isProxy(o) {
    return !!(o && (o instanceof _proxy2.default || o instanceof _proxy.default));
  }
});