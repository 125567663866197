define("ember-fetch-service/services/fetch", ["exports", "@ember/service", "fetch"], function (_exports, _service, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    fetch(url) {
      let init = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return (0, _fetch.default)(url, { ...init,
        headers: { ...(this.headers || {}),
          ...(init.headers || {})
        }
      });
    }

  });

  _exports.default = _default;
});