define("ember-changeset-validations/validators/confirmation", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators/confirmation", "@ember/polyfills"], function (_exports, _validationErrors, _confirmation, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateConfirmation;

  function validateConfirmation() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (key, newValue, _oldValue, changes) {
      let content = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
      // Combine the changes on top of the content so that we evaluate against both default values
      // and valid changes. `changes` only has valid changes that have been made and won't include
      // default values
      let model = (0, _polyfills.assign)({}, content, changes);
      let result = (0, _confirmation.default)(newValue, options, model, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});