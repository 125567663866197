define("ember-changeset-validations/validators/length", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "ember-validators/length"], function (_exports, _validationErrors, _withDefaults, _length) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateLength;

  function validateLength() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = (0, _withDefaults.default)(options, {
      useBetweenMessage: true
    });
    return (key, value) => {
      let result = (0, _length.default)(value, options, null, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }
});