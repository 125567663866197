define("ember-on-helper/helpers/on-window", ["exports", "ember-on-helper/helpers/on"], function (_exports, _on) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _on.default.extend({
    compute(positional, named) {
      return this._super([window, ...positional], named);
    }

  });

  _exports.default = _default;
});