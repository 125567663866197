define("ember-validators/ds-error", ["exports", "ember-validators/utils/validation-error", "@ember/object"], function (_exports, _validationError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDsError;
  _exports.getPathAndKey = getPathAndKey;

  /**
   *  @class DS Error
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Object} model
   * @param {String} attribute
   */
  function validateDsError(value, options, model, attribute) {
    let {
      path,
      key
    } = getPathAndKey(attribute);
    let errors = (0, _object.get)(model, path);

    if (errors && errors.has && errors.has(key)) {
      let errorsFor = errors.errorsFor(key);
      return (0, _validationError.default)('ds', null, options, errorsFor.length ? errorsFor[errorsFor.length - 1].message : []);
    }

    return true;
  }

  function getPathAndKey(attribute) {
    let path = attribute.split('.');
    let key = path.pop();
    path.push('errors');
    return {
      path: path.join('.'),
      key
    };
  }
});